.app {
  display: flex; 
  text-align: center;
}
h1 {
  font-family: 'Raleway';
  font-size: 60px; 
}
body {
  font-family: 'Open Sans';
  font-size: 20px; 
}
.info-section {
  margin: 0 100px 0 100px; 
}
